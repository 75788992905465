body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.clearfix::after {
  display: table;
  clear: both;
  content: '';
}
.paperStyle {
  min-height: 80px;
  min-width: 90px;
  margin: 10px 20px;
  padding: 10px;
  text-align: center;
  display: inline-block;
  float: left;
  cursor: pointer;
}
.paperList ul {
  list-style-type: none;
  padding-left: 0;
  display: table;
}
.paperList li {
  text-align: right;
  display: table-row;
}
.paperList li:hover {
  background-color: #ddf3fe;
}
.paperList .label {
  display: table-cell;
  text-align: left;
  border-bottom: 1px solid lightgray;
}
.paperList .score {
  display: table-cell;
  text-align: right;
  border-bottom: 1px solid lightgray;
}

.cardHeader {
  background-color: #bbdefb;
}
.cardContent {
  padding-top: 6px;
  background-color: #e1f5fe;
}
.pre_resa_title {
  font-size: 1.1em;
  margin: 5px;
  height: auto;
}
.pre_resa_score {
  font-size: 1.5em;
  height: auto;
}
.pre_resa_score_left {
  display: table-cell;
  vertical-align: middle;
  margin-top: 10px;
}
.pre_resa_score_right {
  display: table-cell;
  margin-left: 10px;
}
.pre_resa_ul {
  font-size: 14px;
  line-height: 1.5em;
}
.score {
  font-weight: bold;
}
.sectionTitle {
  font-size: 1.2em;
  font-weight: bold;
}
.clearfix {
  clear: both;
}

.consent-form {
  margin-left: 20px;
}
.consent-form-title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1rem;
  text-decoration: underline;
}
.consent-form-key {
  font-style: italic;
  line-height: 1.8em;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
.consent-form-value {
  margin-left: 5px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.975rem;
  font-weight: 500;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.46429em;
}

.metadatas {
  padding: 5px;
  margin: 2px 0;
  background-color: #fff3d3 !important;
  font-family: 'Verdana';
  color: #444;
}
.metadatas li {
  padding: 5px 0;
  list-style: none;
}
.metadatas a {
  color: rgb(19, 160, 247);
  text-decoration: none;
}
.info-field {
  margin: 10px 0;
}
.info-field label{
  font-weight: bold;
}
.info-field textarea{
  background-color: rgb(255, 241, 197);
  font-family: 'Courier New', Courier, monospace;
}
.info-field-content {
  background-color: rgb(255, 241, 197);
  padding: 8px;
  height: 34px;
  display: flex;
  align-items: center;
}
